import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import { getNumberOfAvailableSlotsAnnouncement } from '../../../../utils/accessibility/announcements/announcements';
import {
  createDatePickerViewModel,
  memoizedDatePickerViewModel,
} from '../datePickerViewModel/datePickerViewModel';
import {
  createTimezoneSelectionViewModel,
  memoizedTimezoneSelectionViewModel,
} from '../timezoneSelectionViewModel/timezoneSelectionViewModel';
import { DailyTimeSlotsLayoutViewModel } from '../bodyViewModel/bodyViewModel.types';
import {
  getNumberOfAvailableTimeSlots,
  getTimeSlotsAvailabilityStatuses,
  TimeSlotAvailabilityStatus,
} from '../../../../utils/timeSlots/timeSlots';
import {
  createTimeSlotsSelectionViewModel,
  memoizedTimeSlotsSelectionViewModel,
} from '../timeSlotsSelectionViewModel/timeSlotsSelectionViewModel';
import {
  createTimeSlotsNotificationViewModel,
  memoizedTimeSlotsNotificationViewModel,
} from '../timeSlotsNotificationViewModel/timeSlotsNotificationViewModel';
import { formatLocalDateTimeToDateView } from '../../../../utils/dateAndTime/dateAndTime';
import {
  createNoAvailableSlotsViewModel,
  memoizedNoAvailableSlotsViewModel,
  NoAvailableSlotsViewModel,
} from '../noAvailableSlotsViewModel/noAvailableSlotsViewModel';

export const memoizedDailyTimeSlotsLayoutViewModel: MemoizedViewModalFactory<DailyTimeSlotsLayoutViewModel> =
  {
    dependencies: {
      settings: [
        'dateAndTimeSectionHeader',
        'dateAndTimeTimezoneSelectionVisibility',
      ],
      state: ['selectedDate', 'slotsStatus', 'availableSlots'],
      subDependencies: [
        memoizedTimezoneSelectionViewModel.dependencies,
        memoizedDatePickerViewModel.dependencies,
        memoizedNoAvailableSlotsViewModel.dependencies,
        memoizedTimeSlotsNotificationViewModel.dependencies,
        memoizedTimeSlotsSelectionViewModel.dependencies,
      ],
    },
    createViewModel: createDailyTimeSlotsLayoutViewModel,
  };

export function createDailyTimeSlotsLayoutViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): DailyTimeSlotsLayoutViewModel {
  const { selectedDate, slotsStatus, availableSlots } = state;
  const {
    getContent,
    settingsParams,
    isMobile,
    businessInfo,
    t,
    experiments,
    settings,
  } = context;

  const timezoneSelectionViewModel =
    (experiments.enabled('specs.bookings.timezoneInDateAndTimeInsteadHeader') &&
      settings.get(settingsParams.dateAndTimeTimezoneSelectionVisibility)) ||
    (!experiments.enabled(
      'specs.bookings.timezoneInDateAndTimeInsteadHeader',
    ) &&
      !isMobile)
      ? createTimezoneSelectionViewModel({
          state,
          context,
        })
      : undefined;

  const dateRegionalSettingsLocale = businessInfo!.dateRegionalSettingsLocale;
  const formattedSelectedDate =
    selectedDate &&
    formatLocalDateTimeToDateView(selectedDate, dateRegionalSettingsLocale);

  const noAvailableSlotsViewModel: NoAvailableSlotsViewModel =
    createNoAvailableSlotsViewModel({
      state,
      context,
    });

  const timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus> =
    getTimeSlotsAvailabilityStatuses(
      availableSlots?.availabilityEntries,
      experiments.enabled('specs.bookings.showWaitListIndicatorOnLockedSlot'),
    );

  const slots = createTimeSlotsSelectionViewModel({
    timeSlotsAvailabilityStatuses,
    shouldHighlightedSlotDetailsOnRender: true,
    state,
    context,
  });

  const timeSlotsNotificationViewModel = createTimeSlotsNotificationViewModel({
    timeSlotsAvailabilityStatuses,
    state,
    context,
  });

  const numberOfAvailableSlots = getNumberOfAvailableTimeSlots(
    timeSlotsAvailabilityStatuses,
  );
  const accessibility = {
    onTimePickerLoadedAnnouncement: getNumberOfAvailableSlotsAnnouncement({
      numberOfAvailableSlots,
      t,
    }),
  };

  return {
    bodyTitle: getContent({
      settingsParam: settingsParams.dateAndTimeSectionHeader,
      translationKey: 'app.settings.defaults.widget.date-and-time-header',
    }),
    timezoneSelectionViewModel,
    datePickerViewModel: createDatePickerViewModel({ state, context }),
    timePicker: {
      status: slotsStatus,
      formattedSelectedDate,
      noAvailableSlotsViewModel,
      timeSlotsNotificationViewModel,
      slots,
      accessibility,
    },
  };
}
